<template>
  <b-modal :id="`detailModal_${id}`" centered size="md" @hide="close">
    <template #modal-title> {{ title }} </template>
    <div :disabled="loading">
      <slot></slot>
    </div>
    <template #modal-footer>
      <div class="d-flex align-items-center justify-content-between w-100">
        <b-button
          type="reset"
          variant="link"
          @click="close"
          class="text-dark text-hover-dark-50 text-decoration-none"
          >İptal</b-button
        >

        <b-button
          type="submit"
          variant="link"
          @click="confirm"
          class="ml-auto text-primary text-hover-primary-50 text-decoration-none"
          >{{ confirmLabel }}</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'DetailModal',
  props: {
    id: {
      type: String,
      default: new Date().getTime().toString(),
    },
    show: Boolean,
    title: String,
    confirmLabel: {
      type: String,
      default: 'DÜZENLE',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    confirm() {
      this.$emit('openInsertUpdate');
      this.close();
    },
    close() {
      this.$bvModal.hide(`detailModal_${this.id}`);
      this.$emit('closeDetail', false);
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.$bvModal.show(`detailModal_${this.id}`);
      } else this.$bvModal.hide(`detailModal_${this.id}`);
    },
  },
};
</script>
